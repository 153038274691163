import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Alert from 'reactstrap/lib/Alert';

import { receiveProducts } from '@ttstr/actions';
import { Container, LoadingSpinner, Maki2020, Masonry, useIntl, ContentrArea } from '@ttstr/components';
import { AppState } from '@ttstr/reducers';
import { useActions, useShallowEqualSelector } from '@ttstr/utils';

const ProductListing: React.FC = () => {
  const { t } = useTranslation();
  const { loading, products } = useShallowEqualSelector(mapStateToProps);
  const { receiveProducts } = useActions(mapDispatchToProps);
  const { language } = useIntl();

  React.useEffect(() => {
    const abortController = new AbortController();
    receiveProducts(undefined, { signal: abortController.signal });
    return () => abortController.abort();
  }, [language]);

  return (
    <article>
      <Helmet>
        <title>{t(`PRODUCTS.TITLE`)}</title>
        <body className="product-listing-page inverse-navbar" />
      </Helmet>
      <div className="headerimage">
        <div className="headerimage-content text-right p-5">
          <h1 className="mb-4">
            {'VOICE – der offizielle Hoodie'}
            <span className="small">{'zur 11. Staffel „The Voice of Germany“'}</span>
          </h1>
          <a href="/product/36885/" className="btn btn-primary btn-lg">
            {'Jetzt entdecken'}
          </a>
        </div>
      </div>
      {/* <img src={require('./assets/images/maex_summer.jpg')} alt="MORD AUF EX" className="w-100 h-100 img-fluid mb-4 d-none d-md-block" /> */}
      <Container className="list-page py-5">
        {/* <h1 className="text-center mb-0 mt-5">{t(`PRODUCTS.TITLE`)}</h1> */}

        <Container>
          <ContentrArea id="before-listing" />
        </Container>

        {loading ? ( // if loading
          <LoadingSpinner label={t(`LOADING.PRODUCTS`)} />
        ) : products.length === 0 ? ( // if no products
          <Alert color="info">{t(`MASONRY.NO_ITEMS`)}</Alert>
        ) : (
          // else show products
          <Masonry id="product-listing" type={Maki2020} products={products} filters="off" showSubtitle />
        )}
      </Container>
    </article>
  );
};

const mapStateToProps = (state: AppState) => {
  const { products, loading } = state.Products;
  return {
    products,
    loading,
  };
};

const mapDispatchToProps = {
  receiveProducts,
};

export default React.memo(ProductListing);
