import React from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';

import { Container } from '@ttstr/components';

const PreFooter: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Row className="my-4 d-flex align-items-center">
        <Col className="align-items-center text-left text-muted copyright">
          <p className="mb-0">
            {'„The Voice of Germany“ immer donnerstags auf ProSieben und sonntags in SAT.1, um 20:15 Uhr'}
          </p>
        </Col>
        <Col sm="12" md="4" className="d-flex align-items-end justify-content-md-end copyright-logos mt-4 mt-md-0">
          <a href="https://www.the-voice-of-germany.de" target="_blank" className="" rel="noreferrer noopener">
            <img
              src={require('./assets/images/p7white.svg')}
              alt="ProSieben"
              title="ProSieben"
              loading="lazy"
              className="img-fluid ml-0"
            />
          </a>
          <a href="https://www.the-voice-of-germany.de" target="_blank" className="" rel="noreferrer noopener">
            <img
              src={require('./assets/images/s1white.png')}
              alt="SAT.1"
              title="SAT.1"
              loading="lazy"
              className="img-fluid ml-5"
            />
          </a>
          <a href="https://www.joyn.de" target="_blank" className="" rel="noreferrer noopener">
            <img
              src={require('./assets/images/joynwhite.svg')}
              alt="joyn"
              title="joyn"
              loading="lazy"
              className="img-fluid ml-5"
            />
          </a>
        </Col>
      </Row>
    </Container>
  );
};

export default React.memo(PreFooter);
